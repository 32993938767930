import * as yup from "yup";

import {
  CREATE_FORM_FIELDS,
  CreateFormType,
  MAX_DENOISING_STEPS,
  MAX_DENSITY,
  MAX_PROMPT_STRENGTH,
  MAX_REFERENCE_SIMILARITY,
  MAX_SEED,
  MAX_STYLE_WEIGHT,
  MIN_DENOISING_STEPS,
  MIN_DENSITY,
  MIN_PROMPT_STRENGTH,
  MIN_REFERENCE_SIMILARITY,
  MIN_SEED,
  MIN_STYLE_WEIGHT,
  PERFORMANCE_OPTIONS,
  REFERENCES_METADATA,
  ReferenceType,
} from "./constants";

const referenceValidationSchema = yup.object().shape({
  image: yup
    .object()
    .shape({
      src: yup.string(),
    })
    .nullable(),
  similarity: yup
    .number()
    .typeError("Invalid value.")
    .min(
      MIN_REFERENCE_SIMILARITY,
      `Similarity must be at least ${MIN_REFERENCE_SIMILARITY}`,
    )
    .max(
      MAX_REFERENCE_SIMILARITY,
      `Similarity must be at most ${MAX_REFERENCE_SIMILARITY}`,
    ),
});

export const createValidationSchema = yup.object().shape({
  [CREATE_FORM_FIELDS.PROMPT_TEXT]: yup
    .string()
    .when(CREATE_FORM_FIELDS.REFERENCES, {
      is: (references: ReferenceType[]) => references.length === 0,
      then: (schema) => schema.required("Prompt text is required."),
    }),
  [CREATE_FORM_FIELDS.NEGATIVE_PROMPT_TEXT]: yup.string(),
  [CREATE_FORM_FIELDS.STYLE]: yup.object().shape({
    id: yup.string(),
    weight: yup
      .number()
      .typeError("Invalid value.")
      .min(MIN_STYLE_WEIGHT, `Weight must be at least ${MIN_STYLE_WEIGHT}.`)
      .max(MAX_STYLE_WEIGHT, `Weight must be at most ${MAX_STYLE_WEIGHT}.`),
  }),
  [CREATE_FORM_FIELDS.PERFORMANCE]: yup
    .string()
    .oneOf(PERFORMANCE_OPTIONS)
    .required("Performance type is required."),
  [CREATE_FORM_FIELDS.REFERENCES]: yup.array().of(referenceValidationSchema),
  [CREATE_FORM_FIELDS.SEED]: yup
    .number()
    .nullable()
    .test(
      "is-valid-seed",
      `Seed must be empty or an integer between ${MIN_SEED} and ${MAX_SEED}.`,
      (value) => value === null || (value >= MIN_SEED && value <= MAX_SEED),
    ),
  [CREATE_FORM_FIELDS.PROMPT_STRENGTH]: yup
    .number()
    .typeError("Invalid value.")
    .required("Prompt strength is required.")
    .min(
      MIN_PROMPT_STRENGTH,
      `Prompt strength must be at least ${MIN_PROMPT_STRENGTH}.`,
    )
    .max(
      MAX_PROMPT_STRENGTH,
      `Prompt strength must be at most ${MAX_PROMPT_STRENGTH}.`,
    ),
  [CREATE_FORM_FIELDS.DENOISING_STEPS]: yup
    .number()
    .typeError("Invalid value.")
    .required("Denoising steps is required")
    .min(
      MIN_DENOISING_STEPS,
      `Denoising steps must be at least ${MIN_DENOISING_STEPS}.`,
    )
    .max(
      MAX_DENOISING_STEPS,
      `Denoising steps must be at most ${MAX_DENOISING_STEPS}.`,
    ),
  [CREATE_FORM_FIELDS.TRANSPARENCY]: yup
    .boolean()
    .required("Transparency is required"),
  [CREATE_FORM_FIELDS.CANVAS_SELECTION]: yup.object().shape({
    type: yup.string().when("image.src", {
      is: (src: string | null) => !!src,
      then: (schema) =>
        schema.required().oneOf(
          Object.keys(REFERENCES_METADATA).filter(
            (key) => REFERENCES_METADATA[key].isLocalized,
          ),
          "Selected Guidance Type is not valid.",
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
    image: yup
      .object()
      .shape({
        src: yup.string(),
      })
      .nullable(),
    similarity: yup
      .number()
      .typeError("Invalid value.")
      .when("image.src", {
        is: (src: string | null) => !!src,
        then: (schema) =>
          schema
            .required()
            .min(
              MIN_REFERENCE_SIMILARITY,
              `Similarity must be at least ${MIN_REFERENCE_SIMILARITY}`,
            )
            .max(
              MAX_REFERENCE_SIMILARITY,
              `Similarity must be at most ${MAX_REFERENCE_SIMILARITY}`,
            ),
        otherwise: (schema) => schema.notRequired(),
      }),
    density: yup
      .number()
      .typeError("Invalid value.")
      .when("image.src", {
        is: (src: string | null) => !!src,
        then: (schema) =>
          schema
            .required()
            .min(MIN_DENSITY, `Density must be at least ${MIN_DENSITY}`)
            .max(MAX_DENSITY, `Density must be at most ${MAX_DENSITY}`),
        otherwise: (schema) => schema.notRequired(),
      }),
  }),
  [CREATE_FORM_FIELDS.DIMENSIONS]: yup
    .array()
    .of(yup.number())
    .length(2)
    .required("Dimensions are required"),
}) as yup.ObjectSchema<CreateFormType>;
