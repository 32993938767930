import { Box, Stack, styled } from "@mui/system";

import { AppWarnings } from "../../../layout/components/AppWarnings/AppWarnings";
import { HelpCenterButton } from "./components/HelpCenterButton/HelpCenterButton";
import { IntercomButton } from "./components/IntercomButton/IntercomButton";
import { ReleaseNotesBox } from "./components/ReleaseNotesBox/ReleaseNotesBox";

const StickyFooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  justifyContent: "flex-end",
  position: "sticky",
  bottom: 0,
  marginBottom: theme.spacing(3),
}));

export const NavFooter = () => (
  <StickyFooterContainer>
    <Stack spacing={1}>
      <AppWarnings />
      <ReleaseNotesBox />
      <IntercomButton />
      <HelpCenterButton />
    </Stack>
  </StickyFooterContainer>
);
